import React, { useState } from "react";
import { Form, Button, Row, Col, Container} from "react-bootstrap";
import { Helmet } from 'react-helmet';

import logo from '../../assets/icon.png';
import { db, storage } from "./firebase"; // Make sure to correctly import your firebase setup
import { collection, addDoc } from "firebase/firestore";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import "./Qualificationform.css"
import "bootstrap/dist/css/bootstrap.min.css";

const Qualificationform = () => {
    // Section 1: Personal Info
  const [personalInfo, setPersonalInfo] = useState({
    firstName: "",
    lastName: "",
    jobTitle: "",
    email: "",
    phoneNumber: "",
  });

  // Section 2: Company Info
  const [companyInfo, setCompanyInfo] = useState({
    businessName: "",
    industry: "",
    numEmployees: "",
    revenue: "",
  });

  // Section 3: Philanthropic Actions and Impact
  const [philanthropicActions, setPhilanthropicActions] = useState([
    { type: "", amount: "", hours: "", description: "", referenceName: "", referenceContact: "", files: [] },
  ]);

  const [successMessage, setSuccessMessage] = useState(null); // New state for success message
  const [errors, setErrors] = useState({});



  const handlePersonalInfoChange = (e) => {
    const { name, value } = e.target;
    setPersonalInfo({ ...personalInfo, [name]: value });
  };

  const handleCompanyInfoChange = (e) => {
    const { name, value } = e.target;
    setCompanyInfo({ ...companyInfo, [name]: value });
  };

  const handlePhilanthropicActionsChange = (index, e) => {
    const { name, value } = e.target;
    const updatedActions = [...philanthropicActions];
  
    // If changing the type (e.g., financial donation, other)
    if (name === `philanthropic-type-${index}`) {
      updatedActions[index] = { ...updatedActions[index], type: value };
    } else {
      updatedActions[index] = { ...updatedActions[index], [name]: value };
    }
    
    setPhilanthropicActions(updatedActions);
  };
  

  const handleFileUpload = (index, e) => {
    const file = e.target.files[0];
    if (file) {
      const storageRef = ref(storage, `files/${file.name}`);
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          // Track progress if necessary
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log(`Upload is ${progress}% done`);
        },
        (error) => {
          console.error("Error uploading file:", error);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((url) => {
            const updatedActions = [...philanthropicActions];
            updatedActions[index].files.push(url);
            setPhilanthropicActions(updatedActions);
          });
        }
      );
    }
  };

  const addPhilanthropicAction = () => {
    setPhilanthropicActions([
      ...philanthropicActions,
      { type: "", amount: "", hours: "", description: "", referenceName: "", referenceContact: "", files: [] },
    ]);
  };

  const removePhilanthropicAction = (index) => {
    if (index > 0) {  // Prevent removal of the first section (index 0)
      const updatedActions = philanthropicActions.filter((_, i) => i !== index);
      setPhilanthropicActions(updatedActions);
    } else {
      alert("The first section cannot be removed.");
    }
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();
  
    const newErrors = {};
  
    // Validate Personal Info
    if (!personalInfo.firstName) newErrors.firstName = "First Name is required.";
    if (!personalInfo.lastName) newErrors.lastName = "Last Name is required.";
    if (!personalInfo.email) newErrors.email = "Email Address is required.";
    if (!personalInfo.jobTitle) newErrors.jobTitle = "Job Title is required.";
    if (!personalInfo.phoneNumber) newErrors.phoneNumber = "Phone Number is required.";
  
    // Validate Company Info
    if (!companyInfo.businessName) newErrors.businessName = "Business Name is required.";
    if (!companyInfo.industry) newErrors.industry = "Industry is required.";
    if (!companyInfo.numEmployees) newErrors.numEmployees = "Number of Employees is required.";
    if (!companyInfo.revenue) newErrors.revenue = "Annual Revenue Range is required.";
  
    // If there are errors, prevent form submission and display error messages
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }
  
    const formData = {
      personalInfo,
      companyInfo,
      philanthropicActions,
    };
  
    try {
      // Add form data to Firestore
      await addDoc(collection(db, "submissions"), formData);
  
      // Clear form data
      setPersonalInfo({
        firstName: "",
        lastName: "",
        jobTitle: "",
        email: "",
        phoneNumber: "",
      });
      setCompanyInfo({
        businessName: "",
        industry: "",
        numEmployees: "",
        revenue: "",
      });
      setPhilanthropicActions([
        { type: "", amount: "", hours: "", description: "", referenceName: "", referenceContact: "", files: [] },
      ]);
  
      // Show success message
      setSuccessMessage("Form submitted successfully!");
      window.scrollTo(0, 0);
    } catch (error) {
      console.error("Error submitting form: ", error);
      alert("Error submitting form");
    }
  };

  return (

    <div className="qualification-form">

        <Container className="my-4">

        <Helmet>
        <title>Bunchful Spotlight Program</title>
        </Helmet>

        <div style={{ textAlign: "center", marginBottom: "5px" }}>
            <img src={logo} alt="Company Logo" style={{ maxWidth: "100px" }} />
            <h1>Prequalify for the Bunchful Spotlight Program</h1>
            <p style={{
                fontSize: "1rem",
                fontWeight: "500",
                color: "#555",
                borderRadius: "5px",
                display: "inline-block",
                maxWidth: "80%",
                margin: "0 auto"
                }}
            >
                Bunchful is a special accredited member of the United Nations' 2030 Summit of the Future.
            </p>
            <p style={{fontWeight: "500", color: "#555"}}>Ask how you may partner with us on this program.</p>
            </div>


            {successMessage && (
            <div className="alert alert-success">
            {successMessage}
            </div>
            )}

            <Form onSubmit={handleSubmit}>
            {/* Section 1: Personal Info */}
            <h2>1. Personal Information</h2>
            <Form.Group controlId="firstName">
                <Form.Control
                type="text"
                name="firstName"
                value={personalInfo.firstName}
                onChange={handlePersonalInfoChange}
                isInvalid={!!errors.firstName}
                placeholder="First Name"
                style={{ marginBottom: "2%", marginTop: "2%"}}
                />
                <Form.Control.Feedback type="invalid">{errors.firstName}</Form.Control.Feedback>
            </Form.Group>
            
            <Form.Group controlId="lastName">
                <Form.Control
                type="text"
                name="lastName"
                value={personalInfo.lastName}
                onChange={handlePersonalInfoChange}
                isInvalid={!!errors.lastName}
                placeholder="Last Name"
                style={{ marginBottom: "2%" }}
                />
                <Form.Control.Feedback type="invalid">{errors.lastName}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="jobTitle">
                <Form.Control
                type="text"
                name="jobTitle"
                value={personalInfo.jobTitle}
                onChange={handlePersonalInfoChange}
                isInvalid={!!errors.jobTitle}
                placeholder="Job Title"
                style={{ marginBottom: "2%" }}
                />
                <Form.Control.Feedback type="invalid">{errors.jobTitle}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="email">
                <Form.Control
                type="email"
                name="email"
                value={personalInfo.email}
                onChange={handlePersonalInfoChange}
                isInvalid={!!errors.email}
                placeholder="Email Address"
                style={{ marginBottom: "2%" }}
                />
                <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="phoneNumber">
                <Form.Control
                type="tel"
                name="phoneNumber"
                value={personalInfo.phoneNumber}
                onChange={handlePersonalInfoChange}
                isInvalid={!!errors.phoneNumber}
                placeholder="Phone Number"
                style={{ marginBottom: "2%" }}
                />
                <Form.Control.Feedback type="invalid">{errors.phoneNumber}</Form.Control.Feedback>
            </Form.Group>

            {/* Section 2: Company Info */}
            <h2>2. Company Information</h2>
            <Form.Group controlId="businessName">
                <Form.Control
                type="text"
                name="businessName"
                value={companyInfo.businessName}
                onChange={handleCompanyInfoChange}
                isInvalid={!!errors.businessName}
                placeholder="Company Name"
                style={{ marginBottom: "2%", marginTop: "2%"}}
                />
                <Form.Control.Feedback type="invalid">{errors.businessName}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="industry">
                <Form.Control
                as="select"
                name="industry"
                value={companyInfo.industry}
                onChange={handleCompanyInfoChange}
                isInvalid={!!errors.industry}
                style={{ marginBottom: "2%" }}
                >
                <option value="">Select Industry</option>
                <option value="Agriculture & Food">Agriculture & Food</option>
                <option value="Automotive & Transportation">Automotive & Transportation</option>
                <option value="Banking, Finance & Insurance">Banking, Finance & Insurance</option>
                <option value="Construction & Real Estate">Construction & Real Estate</option>
                <option value="Education & Training">Education & Training</option>
                <option value="Healthcare & Life Sciences">Healthcare & Life Sciences</option>
                <option value="Hospitality, Travel & Tourism">Hospitality, Travel & Tourism</option>
                <option value="Information Technology & Telecommunications">Information Technology & Telecommunications</option>
                <option value="Manufacturing & Industrial">Manufacturing & Industrial</option>
                <option value="Retail & Consumer Services">Retail & Consumer Services</option>
                </Form.Control>
                <Form.Control.Feedback type="invalid">{errors.industry}</Form.Control.Feedback>

            </Form.Group>

            <Form.Group controlId="numEmployees">
                <Form.Control
                as="select"
                name="numEmployees"
                value={companyInfo.numEmployees}
                onChange={handleCompanyInfoChange}
                isInvalid={!!errors.numEmployees}
                style={{ marginBottom: "2%" }}
                >
                <option value="">Select Number of Employees</option>
                <option value="1-10">1-10</option>
                <option value="11-50">11-50</option>
                <option value="51-200">51-200</option>
                <option value="201-500">201-500</option>
                <option value="501-1000">501-1000</option>
                <option value="1001-5000">1001-5000</option>
                <option value="5001-10000">5001-10000</option>
                <option value="10001+">10000+</option>
                </Form.Control>
                <Form.Control.Feedback type="invalid">{errors.numEmployees}</Form.Control.Feedback>

            </Form.Group>

            <Form.Group controlId="revenue">
                <Form.Control
                as="select"
                name="revenue"
                value={companyInfo.revenue}
                onChange={handleCompanyInfoChange}
                isInvalid={!!errors.revenue}
                style={{ marginBottom: "2%" }}
                >
                <option value="">Select Revenue Range</option>
                <option value="Less than $500,000">Less than $500,000</option>
                <option value="$500,000 - $1 million">$500,000 - $1 million</option>
                <option value="$1 million - $5 million">$1 million - $5 million</option>
                <option value="$5 million - $10 million">$5 million - $10 million</option>
                <option value="Over $10 million">Over $10 million</option>
                </Form.Control>
                <Form.Control.Feedback type="invalid">{errors.revenue}</Form.Control.Feedback>
            </Form.Group>

            {/* Section 3: Philanthropic Actions and Impact */}
            <h2>3. Philanthropic Actions and Impact</h2>
            {philanthropicActions.map((action, index) => (
                <div key={index}>
                {/* Philanthropic Action Type */}
                <fieldset>
                    <Form.Check
                    type="radio"
                    label="Financial Donation"
                    name={`philanthropic-type-${index}`}
                    value="financial donation"
                    checked={action.type === "financial donation"}
                    onChange={(e) => handlePhilanthropicActionsChange(index, e)}
                    />
                    {action.type === "financial donation" && (
                    <Form.Control
                        type="number"
                        name="amount"
                        value={action.amount}
                        onChange={(e) => handlePhilanthropicActionsChange(index, e)}
                        placeholder="Donation Amount"
                    />
                    )}
                    <Form.Check
                    type="radio"
                    label="Employee Volunteer Programs"
                    name={`philanthropic-type-${index}`}
                    value="employee volunteer programs"
                    checked={action.type === "employee volunteer programs"}
                    onChange={(e) => handlePhilanthropicActionsChange(index, e)}
                    />
                    {action.type === "employee volunteer programs" && (
                    <Form.Control
                        type="number"
                        name="hours"
                        value={action.hours}
                        onChange={(e) => handlePhilanthropicActionsChange(index, e)}
                        placeholder="Hours Volunteered"
                    />
                    )}
                    <Form.Check
                    type="radio"
                    label="In-kind Donations"
                    name={`philanthropic-type-${index}`}
                    value="in-kind donations"
                    checked={action.type === "in-kind donations"}
                    onChange={(e) => handlePhilanthropicActionsChange(index, e)}
                    />
                    {action.type === "in-kind donations" && (  
                    <>
                        <p style={{ fontStyle: "italic", color: "#555", marginTop: "5px" }}>
                        In-kind donations are non-cash contributions of goods or services, such as equipment, software, or volunteer hours, provided to an organization.
                        </p>
                        <Form.Control
                        type="number"
                        name="amount"
                        value={action.amount}
                        onChange={(e) => handlePhilanthropicActionsChange(index, e)}
                        placeholder="Estimated Monetary Value"
                        />
                    </>
                    )}

                    <Form.Check
                    type="radio"
                    label="Environmental Initiatives"
                    name={`philanthropic-type-${index}`}
                    value="environmental initiatives"
                    checked={action.type === "environmental initiatives"}
                    onChange={(e) => handlePhilanthropicActionsChange(index, e)}
                    />
                    <Form.Check
                    type="radio"
                    label="Other"
                    name={`philanthropic-type-${index}`}
                    value="other"
                    checked={action.type === "other"}
                    onChange={(e) => handlePhilanthropicActionsChange(index, e)}
                    />
                    {action.type === "other" && (
                    <Form.Control
                        type="text"
                        name="description"
                        value={action.description}
                        onChange={(e) => handlePhilanthropicActionsChange(index, e)}
                        placeholder="Describe your philanthropic action"
                    />
                    )}
                </fieldset>
                {/* Reference Name, Reference Email, and Reference Phone Number */}
                <Row style={{ paddingBottom: "2%" }}>
                    <Col sm={12} style={{ paddingBottom: "2%",paddingTop: "1%"}}>
                    <Form.Group controlId={`referenceName-${index}`}>
                        <Form.Control
                        type="text"
                        name="referenceName"
                        value={action.referenceName}
                        onChange={(e) => handlePhilanthropicActionsChange(index, e)}
                        placeholder="Reference the name for someone at the organization that received the donation, so we may validate this information."
                        />
                    </Form.Group>
                    </Col>

                    <Col sm={12} style={{ paddingBottom: "2%" }}>
                    <Form.Group controlId={`referenceEmail-${index}`}>
                        <Form.Control
                        type="email"
                        name="referenceEmail"
                        value={action.referenceEmail}
                        onChange={(e) => handlePhilanthropicActionsChange(index, e)}
                        placeholder="Reference Email"
                        />
                    </Form.Group>
                    </Col>

                    <Col sm={12}>
                    <Form.Group controlId={`referencePhoneNumber-${index}`}>
                        <Form.Control
                        type="tel"
                        name="referencePhoneNumber"
                        value={action.referencePhoneNumber}
                        onChange={(e) => handlePhilanthropicActionsChange(index, e)}
                        placeholder="Reference Phone Number"
                        />
                    </Form.Group>
                    </Col>
                </Row>

                {/* File Upload */}
                <Form.Group controlId={`fileUpload-${index}`}>
                    <Form.Control
                    type="file"
                    onChange={(e) => handleFileUpload(index, e)}
                    />
                    {action.files.length > 0 && (
                    <div>
                        <strong>Uploaded Files:</strong>
                        <ul>
                        {action.files.map((file, fileIndex) => (
                            <li key={fileIndex}>
                            <a href={file} target="_blank" rel="noopener noreferrer">View File</a>
                            </li>
                        ))}
                        </ul>
                    </div>
                    )}
                </Form.Group>

                <Form.Group controlId={`additionalComments-${index}`}>
                    <Form.Control
                    as="textarea"
                    rows={5}
                    name="additionalComments"
                    value={action.additionalComments || ""}
                    onChange={(e) => {
                        const updatedActions = [...philanthropicActions];
                        updatedActions[index] = { ...updatedActions[index], additionalComments: e.target.value };
                        setPhilanthropicActions(updatedActions);
                    }}
                    placeholder="Enter any additional details, links, or websites here."
                    style={{ marginTop: "2%", marginBottom: "2%" }}
                    />
                </Form.Group>

                {index > 0 && (
                    <Button
                    variant="danger"
                    onClick={() => removePhilanthropicAction(index)}
                    style={{ marginTop: "10px" }}
                    >
                    Remove Section
                    </Button>
                )}
                </div>
            ))}

                <div className="button-container">
                    <Button variant="secondary" onClick={addPhilanthropicAction} className="action-button">
                    Add Another Philanthropic Action
                    </Button>

                    <Button variant="primary" type="submit" className="submit-button">
                    Submit Form
                    </Button>
                </div>
            </Form>

            <div className="disclaimer" style={{ textAlign: "center", marginTop: "20px", color: "#555", fontStyle: "italic", fontSize: "0.85rem" }}>
                <p style={{marginBottom: "6px"}}>
                    Not all businesses may qualify for Bunchful Spotlight and other Bunchful programs.
                </p>
                <p>
                    A member of our team will contact you with next steps.
                </p>
            </div>
        </Container>
    </div>
  );
};

export default Qualificationform