// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.privacy-policy .section-1 {
    background-color: #f3f2ff;
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 5%;
    padding-bottom: 5%;
}

.privacy-policy .section-1 .heading {
    font-size: 3rem;
}

.privacy-policy .section-1 .text {
    font-size: 1.4rem;
    padding-top: 2%;
    line-height: 170%;
}
`, "",{"version":3,"sources":["webpack://./src/Pages/PrivacyPolicy/Privacypolicy.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,gBAAgB;IAChB,iBAAiB;IACjB,eAAe;IACf,kBAAkB;AACtB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,iBAAiB;IACjB,eAAe;IACf,iBAAiB;AACrB","sourcesContent":[".privacy-policy .section-1 {\n    background-color: #f3f2ff;\n    padding-left: 5%;\n    padding-right: 5%;\n    padding-top: 5%;\n    padding-bottom: 5%;\n}\n\n.privacy-policy .section-1 .heading {\n    font-size: 3rem;\n}\n\n.privacy-policy .section-1 .text {\n    font-size: 1.4rem;\n    padding-top: 2%;\n    line-height: 170%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
