import React from 'react';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import Logo from "../../assets/header-logo.webp"; 
import { Nav } from 'react-bootstrap'; // Add this import
import './Header.css'; // Import custom CSS

const Header = () => {
  return (
    <header className='header'>
      <Navbar collapseOnSelect expand="lg" className="custom-navbar">
        <Container>
          {/* Logo on the left */}
          <Navbar.Brand as={Link} to="/" className="logo">
            <img src={Logo} alt="Logo" width="180" height="auto" />
          </Navbar.Brand>

          {/* Navigation Links */}
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="ms-auto">
              {/* Use HashLink for smooth scrolling, wrapped with Nav.Link */}
              <Nav.Link as={HashLink} smooth to="#why-bunchful-ai">Why Bunchful AI</Nav.Link>
              <Nav.Link as={HashLink} smooth to="#how-bunchful-ai">How Bunchful AI</Nav.Link>
              <Nav.Link as={HashLink} smooth to="#benefits-of-bunchful-ai">Benefits</Nav.Link>
              <Nav.Link as={HashLink} smooth to="#how-it-works">Get Started</Nav.Link>
              <Nav.Link as={HashLink} smooth to="#spotlight-program">Join Bunchful Spotlight</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  );
};

export default Header;