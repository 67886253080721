// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.not-found .section-1 {
    background-color: #eae5f9; 
    padding: 40px; /* Increased padding for more space */
}

.not-found .not-found-text {
    font-size: 26px; /* Slightly larger font size for better readability */
    margin-bottom: 30px; /* More space below the text */
    color: #434343; 
}

.not-found .return-container {
    display: flex; 
    justify-content: center; 
    margin-top: 20px; 
}


.not-found .return-button {
    text-decoration: none; 
    background-color: #f2506e; 
    border: #f2506e;
    color: white; 
    padding: 12px 24px; /* Increased padding for the button */
    border-radius: 5px; 
    transition: background-color 0.3s, transform 0.2s; /* Added transform transition */
    font-size: 18px; /* Larger font size for better visibility */
}

.not-found .return-button:hover {
    background-color: #eae5f9; 
    border: 1px solid #f2506e;
    color: #f2506e;
    transform: scale(1.05); /* Slightly enlarge on hover for effect */
}

.not-found .return-button:active {
    transform: scale(0.95); /* Slightly shrink on click for effect */
}
`, "",{"version":3,"sources":["webpack://./src/Pages/Notfound/Notfound.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,aAAa,EAAE,qCAAqC;AACxD;;AAEA;IACI,eAAe,EAAE,qDAAqD;IACtE,mBAAmB,EAAE,8BAA8B;IACnD,cAAc;AAClB;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,gBAAgB;AACpB;;;AAGA;IACI,qBAAqB;IACrB,yBAAyB;IACzB,eAAe;IACf,YAAY;IACZ,kBAAkB,EAAE,qCAAqC;IACzD,kBAAkB;IAClB,iDAAiD,EAAE,+BAA+B;IAClF,eAAe,EAAE,2CAA2C;AAChE;;AAEA;IACI,yBAAyB;IACzB,yBAAyB;IACzB,cAAc;IACd,sBAAsB,EAAE,yCAAyC;AACrE;;AAEA;IACI,sBAAsB,EAAE,wCAAwC;AACpE","sourcesContent":[".not-found .section-1 {\n    background-color: #eae5f9; \n    padding: 40px; /* Increased padding for more space */\n}\n\n.not-found .not-found-text {\n    font-size: 26px; /* Slightly larger font size for better readability */\n    margin-bottom: 30px; /* More space below the text */\n    color: #434343; \n}\n\n.not-found .return-container {\n    display: flex; \n    justify-content: center; \n    margin-top: 20px; \n}\n\n\n.not-found .return-button {\n    text-decoration: none; \n    background-color: #f2506e; \n    border: #f2506e;\n    color: white; \n    padding: 12px 24px; /* Increased padding for the button */\n    border-radius: 5px; \n    transition: background-color 0.3s, transform 0.2s; /* Added transform transition */\n    font-size: 18px; /* Larger font size for better visibility */\n}\n\n.not-found .return-button:hover {\n    background-color: #eae5f9; \n    border: 1px solid #f2506e;\n    color: #f2506e;\n    transform: scale(1.05); /* Slightly enlarge on hover for effect */\n}\n\n.not-found .return-button:active {\n    transform: scale(0.95); /* Slightly shrink on click for effect */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
