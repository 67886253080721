import React from "react";
import { Container, Row, Col } from 'react-bootstrap';
import "./homepage.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";

import sdgImage from "../../assets/sdgs.webp";
import globalCauses from "../../assets/globalcauses.webp";
import storytelling from "../../assets/storytelling.webp";
import csrManagement from "../../assets/csr_management.webp";
import step3 from "../../assets/Step-3.webp";
import bunchfulSpotlightLogo from "../../assets/26.webp";

import wetell from "../../assets/we-tell.webp";
import inspiremovement from "../../assets/inspire-movement.webp";
import turnaction from "../../assets/turn-action.webp";
import formimage from "../../assets/form-img.webp";

import philanthropicactivity from "../../assets/philanthropic-activities.webp";

const Homepage = () => {

    return (
        <div className="homepage">

            <Header/>

            <section className="hero-hook">
                <Container>
                    <Row className="first-row">
                        <Col md={6} className="first-column">
                            <h2 className="hook">Be the Leader in Giving <br/>That Everyone Talks About</h2>
                            <p className="hero">Bunchful AI ensures your philanthropic impact isn't just seen it's celebrated. Qualify for the spotlight today.</p>
                            <div className="button-container">
                                <a 
                                    href="/qualification-form" 
                                    className="qualify-now-button" 
                                    target="_blank" 
                                    rel="noopener noreferrer"
                                    >
                                    Qualify Now
                                </a>
                            </div>
                        </Col>

                        <Col md={6} className="second-column">
                            <img src={bunchfulSpotlightLogo} 
                                alt="Logo" 
                                className="first-image"
                            />
                        </Col>
                    </Row>
                </Container>
            </section>

            <div className="separator"/>

            <section id="why-bunchful-ai" className="why-bunchful-ai">
                <Container>
                    <h1 className="title">Why Bunchful AI?</h1>
                    <Row className="rows" style={{backgroundColor: "#f2f2ff"}}>
                        <Col md={6} className="first-column">
                            <h2 className="section-title">Because giving isn't just about impact; it's about connection.</h2>
                            <p className="section-description">
                                Bunchful AI bridges your generosity with global change, showcasing your leadership while aligning your actions with meaningful results.                            
                            </p>
                            <span className="cta-label">Lead with Purpose. <br/> Inspire Change.</span>
                        </Col>
                        <Col md={6} className="second-column">
                            <img
                                src={globalCauses}
                                alt="Global Causes"
                                className="first-section-image"
                            />
                        </Col>
                    </Row>

                    <div className="separator"/>

                    <Row className="rows" style={{backgroundColor: "#ffede8"}}>
                        <Col md={6} className="second-column">
                            <img
                                src={storytelling}
                                alt="Storytelling"
                                className="second-section-image"
                            />
                        </Col>
                        <Col md={6} className="first-column">
                            <h2 className="section-title">Clarity Meets Action</h2>
                            <p className="section-description">
                                Bunchful AI doesn't just amplify your giving—it provides clear, measurable insights, showing how your generosity directly contributes to global progress.                            </p>
                            <span className="cta-label">Amplify Your Impact Today.</span>
                        </Col>
                    </Row>

                    <div className="separator"/>

                    <Row  className="rows" style={{backgroundColor:"#f2e9fd"}}>
                        <Col md={6} className="first-column">
                            <h2 className="section-title">Turn Impact Into Influence</h2>
                            <p className="section-description">
                                With Bunchful AI, your philanthropic efforts inspire a ripple effect, influencing partners, stakeholders, and communities to join you in creating lasting change.                            </p>
                            <span className="cta-label">Elevate Your CSR Today.</span>
                        </Col>
                        <Col md={6} className="second-column">
                            <img
                                src={csrManagement}
                                alt="CSR Management"
                                className="third-section-image"
                            />
                        </Col>
                    </Row>
                </Container>
            </section>

            <div className="separator"/>

            <section id="how-bunchful-ai" className="how-bunchful-ai">
                <Container>
                    <h1 className="title">How Bunchful AI Works?</h1>

                    <Row className="rows" style={{backgroundColor:"#b2fff4"}}>
                        <Col md={6} className="first-column">
                            <h2 className="section-title">We Tell Your Story</h2>
                            <p className="section-description">
                                Your giving deserves recognition. Bunchful AI transforms your efforts into a compelling narrative that inspires others.                                                            
                            </p>
                            <span className="cta-label">Share Your Impact.<br/> Inspire Others.</span>
                        </Col>
                        <Col md={6} className="second-column">
                            <img
                                src={wetell}
                                alt="Global Causes"
                                className="first-section-image"
                                style={{ width: "90%", borderRadius: "8px" }}
                            />
                        </Col>
                    </Row>

                <div className="separator"/>

                    <Row className ="rows" style={{backgroundColor:"#b3b3fd"}}>
                        <Col md={6} className="second-column">
                            <img
                                src={inspiremovement}
                                alt="Inspire a movement"
                                className="second-section-image"
                            />
                        </Col>
                        <Col md={6} className="first-column">
                            <h2 className="section-title">Inspire a Movement</h2>
                            <p className="section-description">
                                Leadership in giving means showing others the way. Bunchful AI helps your generosity ignite action and foster change.
                            </p>
                            <span className="cta-label">Lead by Example. <br/> Spark Change.</span>
                        </Col>
                    </Row>

                    <div className="separator"/>

                    <Row className="rows" style={{backgroundColor:"#ffbbaa"}}>
                        <Col md={6} className="first-column">
                            <h2 className="section-title">Turn Action into Impact</h2>
                            <p className="section-description">
                                Bunchful AI ensures your generosity resonates far beyond the moment, reaching communities, stakeholders, and partners.
                            </p>
                            <span className="cta-label">Make Your Impact Last.</span>
                        </Col>
                        <Col md={6} className="second-column">
                            <img
                                src= {turnaction}
                                alt="CSR Management"
                                className="third-section-image"
                            />
                        </Col>
                    </Row>
                </Container>
            </section>

            <div className="separator"/>

            <section className="single-section">
                <Container>
                    <h1 className="title">Align Your Giving with Global Goals</h1>
                    <Col className="first-col">
                        <Row>
                            <p className="section-description">
                            Bunchful AI doesn't just amplify your story—it aligns your giving with the United Nations Sustainable Development Goals (SDGs). These 17 global goals tackle challenges like poverty, education, and climate change, helping measure the impact of your generosity.
                            <br/><br/>Example: Donations to education align with SDG 4: Quality Education, while sustainability efforts support SDG 13: Climate Action. We help your giving connect to global progress. </p>                           
                        </Row>

                        <Row className="second-row">
                            <img 
                                src={sdgImage} 
                                alt="The 17 SDGs"
                                className="first-image" 
                            />
                        </Row>
                        <span className="cta-label">Lead with Purpose. Market with Impact.</span>
                    </Col>                   
                </Container>
            </section>

            <div className="separator"/>

            <section className="benefits-of-bunchful-ai" id="benefits-of-bunchful-ai">
                <Container>
                    <h1 className="title">
                        Benefits of Bunchful AI
                    </h1>
                    <Row className="g-4 text-center">
                        <Col md={6} lg={4}>
                            <div
                                className="card h-100 shadow-sm"
                                style={{
                                    borderRadius: "10px",
                                    transition: "transform 0.3s ease, box-shadow 0.3s ease",
                                }}
                                onMouseOver={(e) => {
                                    e.currentTarget.style.transform = "translateY(-10px)";
                                    e.currentTarget.style.boxShadow = "0 8px 20px rgba(0, 0, 0, 0.2)";
                                }}
                                onMouseOut={(e) => {
                                    e.currentTarget.style.transform = "translateY(0)";
                                    e.currentTarget.style.boxShadow = "0 4px 10px rgba(0, 0, 0, 0.1)";
                                }}
                            >
                                <div className="card-body">
                                    <h5 className="card-title" style={{ fontWeight: "600", color: "#333" }}>Lead the Conversation on Generosity</h5>
                                    <p className="card-text">
                                        Bunchful AI takes your philanthropy to the next level-amplifying your impact and inspiring the world
                                    </p>
                                </div>
                            </div>
                        </Col>
                        <Col md={6} lg={4}>
                            <div
                                className="card h-100 shadow-sm"
                                style={{
                                    borderRadius: "10px",
                                    transition: "transform 0.3s ease, box-shadow 0.3s ease",
                                }}
                                onMouseOver={(e) => {
                                    e.currentTarget.style.transform = "translateY(-10px)";
                                    e.currentTarget.style.boxShadow = "0 8px 20px rgba(0, 0, 0, 0.2)";
                                }}
                                onMouseOut={(e) => {
                                    e.currentTarget.style.transform = "translateY(0)";
                                    e.currentTarget.style.boxShadow = "0 4px 10px rgba(0, 0, 0, 0.1)";
                                }}
                            >
                                <div className="card-body">
                                    <h5 className="card-title" style={{ fontWeight: "600", color: "#333" }}>Global Recognition</h5>
                                    <p className="card-text">
                                        Your philanthropic leadership showcased as a model for others to follow and presented  in ways that resonate with your audience and stakeholders
                                    </p>
                                </div>
                            </div>
                        </Col>
                        <Col md={6} lg={4}>
                            <div
                                className="card h-100 shadow-sm"
                                style={{
                                    borderRadius: "10px",
                                    transition: "transform 0.3s ease, box-shadow 0.3s ease",
                                }}
                                onMouseOver={(e) => {
                                    e.currentTarget.style.transform = "translateY(-10px)";
                                    e.currentTarget.style.boxShadow = "0 8px 20px rgba(0, 0, 0, 0.2)";
                                }}
                                onMouseOut={(e) => {
                                    e.currentTarget.style.transform = "translateY(0)";
                                    e.currentTarget.style.boxShadow = "0 4px 10px rgba(0, 0, 0, 0.1)";
                                }}
                            >
                                <div className="card-body">
                                    <h5 className="card-title" style={{ fontWeight: "600", color: "#333" }}>Turn Your Generosity into a Movement</h5>
                                    <p className="card-text">
                                        Don't just give—lead. Bunchful AI spreads your story to drive global impact and automatically matches them with relevant UN SDGs to ensure alignment with global goals
                                    </p>
                                </div>
                            </div>
                        </Col>
                        <Col md={6} lg={4}>
                            <div
                                className="card h-100 shadow-sm"
                                style={{
                                    borderRadius: "10px",
                                    transition: "transform 0.3s ease, box-shadow 0.3s ease",
                                }}
                                onMouseOver={(e) => {
                                    e.currentTarget.style.transform = "translateY(-10px)";
                                    e.currentTarget.style.boxShadow = "0 8px 20px rgba(0, 0, 0, 0.2)";
                                }}
                                onMouseOut={(e) => {
                                    e.currentTarget.style.transform = "translateY(0)";
                                    e.currentTarget.style.boxShadow = "0 4px 10px rgba(0, 0, 0, 0.1)";
                                }}
                            >
                                <div className="card-body">
                                    <h5 className="card-title" style={{ fontWeight: "600", color: "#333" }}>Enhanced Collaboration</h5>
                                    <p className="card-text">
                                        We help you connect with partners and organizations aligned with your goals to expand your impact
                                    </p>
                                </div>
                            </div>
                        </Col>
                        <Col md={6} lg={4}>
                            <div
                                className="card h-100 shadow-sm"
                                style={{
                                    borderRadius: "10px",
                                    transition: "transform 0.3s ease, box-shadow 0.3s ease",
                                }}
                                onMouseOver={(e) => {
                                    e.currentTarget.style.transform = "translateY(-10px)";
                                    e.currentTarget.style.boxShadow = "0 8px 20px rgba(0, 0, 0, 0.2)";
                                }}
                                onMouseOut={(e) => {
                                    e.currentTarget.style.transform = "translateY(0)";
                                    e.currentTarget.style.boxShadow = "0 4px 10px rgba(0, 0, 0, 0.1)";
                                }}
                            >
                                <div className="card-body">
                                    <h5 className="card-title" style={{ fontWeight: "600", color: "#333" }}>Tailored Messaging</h5>
                                    <p className="card-text">
                                        Your generosity, presented in ways that resonate with your audience and stakeholders                                  </p>
                                </div>
                            </div>
                        </Col>
                        <Col md={6} lg={4}>
                            <div
                                className="card h-100 shadow-sm"
                                style={{
                                    borderRadius: "10px",
                                    transition: "transform 0.3s ease, box-shadow 0.3s ease",
                                }}
                                onMouseOver={(e) => {
                                    e.currentTarget.style.transform = "translateY(-10px)";
                                    e.currentTarget.style.boxShadow = "0 8px 20px rgba(0, 0, 0, 0.2)";
                                }}
                                onMouseOut={(e) => {
                                    e.currentTarget.style.transform = "translateY(0)";
                                    e.currentTarget.style.boxShadow = "0 4px 10px rgba(0, 0, 0, 0.1)";
                                }}
                            >
                                <div className="card-body">
                                    <h5 className="card-title" style={{ fontWeight: "600", color: "#333" }}>Your Philanthropy. Spotlighted. Amplified. Celebrated</h5>
                                    <p className="card-text">
                                        Bunchful AI turns your giving into a powerful story that inspires
                                    </p>
                                </div>
                            </div>
                        </Col>
                    </Row>

                    <div className="text-center mt-4">
                        <a
                            href="/qualification-form"
                            className="btn btn-primary btn-lg"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                                backgroundColor: "#5050f3",
                                borderColor: "#5050f3",
                                color: "white",
                                padding: "12px 30px",
                                fontSize: "1.2rem",
                                letterSpacing: "0.5px",
                                borderRadius: "8px",
                                transition: "all 0.3s ease",
                            }}
                            onMouseOver={(e) => {
                                e.target.style.backgroundColor = "#333";
                                e.target.style.borderColor = "#333";
                            }}
                            onMouseOut={(e) => {
                                e.target.style.backgroundColor = "#5050f3";
                                e.target.style.borderColor = "#5050f3";
                            }}
                        >
                            Qualify Now
                        </a>
                    </div>
                </Container>
            </section>

            <div className="separator"/>

            <section id="how-it-works" className="how-it-works">
                <Container>
                    <h1 className="title">How to Get Started with Bunchful AI?</h1>

                    <Row className="rows">
                        <Col md={6} className="first-column">
                            <h3 className="text">
                                Step 1: Provide your information along with company details on this
                                <a 
                                    href="/qualification-form" 
                                    className="prequalification-link" 
                                    target="_blank" 
                                    rel="noopener noreferrer"
                                > Prequalification Form.
                                </a>
                            </h3>
                        </Col>
                        <Col md={6} className="second-column">
                            <img 
                                src={formimage} 
                                alt="Step 1" 
                                className="first-step-image" 
                            />
                        </Col>
                    </Row>

                    <div className="separator"/>

                    <Row className="rows">
                        <Col md={6} className="first-column">
                            <h3 className="text">Step 2: Enter Your Philanthropic Activities.</h3>
                        </Col>
                        <Col md={6} className="second-column">
                            <img 
                                src={philanthropicactivity} 
                                alt="Step 2" 
                                className="second-step-image" 
                            />
                        </Col>
                    </Row>

                    <div className="separator"/>

                    <Row className="rows">
                        <Col md={6}>
                            <h3 className="text">Step 3: Follow our guide to match your giving to the global goal it supports.</h3>
                        </Col>
                        <Col md={6} className="second-column">
                            <img 
                                src={step3} 
                                alt="Step 3" 
                                className="third-step-image" 
                            />
                        </Col>
                    </Row>
                </Container>
            </section>

            <div className="separator"/>

            <section id="spotlight-program" className="cta-section">
                <Container className="first-container">
                    <h3 className="title">Why Wait? Start Leading in Giving Today</h3>
                    <p className="text">
                        The world needs leaders like you—leaders who aren't afraid to take bold action and show what's possible through generosity. With Bunchful AI, you're not just giving; you're transforming lives, inspiring change, and building a legacy.
                        Qualify for your spotlight today.
                    </p>

                    <a  href="/qualification-form" 
                        className="apply-now-button" 
                        target="_blank" 
                        rel="noopener noreferrer"
                        >
                        Apply Now to See If You Qualify
                    </a>
        
                </Container>
            </section>

            <div className="separator"/>

            <Footer/>
        </div>
    );
};

export default Homepage;