import React from 'react';
import { Container, Row, Col} from 'react-bootstrap';
import sdg17 from '../../assets/17-partnership.webp';
import { 
    FaFacebook, FaInstagram, FaTwitter, 
    FaYoutube, FaLinkedin, FaPhone, FaEnvelope, 
    FaMapMarkerAlt 
} from 'react-icons/fa';
import './Footer.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom';


const Footer = () => {

    return (
        <footer className="footer">
            <Container fluid>
                {/* SDG 17 Section */}
                <Row className="mb-4 text-center justify-content-center">
                    <Col xs={12} md={4} className="d-flex flex-column align-items-center">
                        <h3 className='sdg-17'>SDG 17 is the main goal for Bunchful Enterprise</h3>
                        <div className="footer-images mb-3">
                            <img src={sdg17} alt="SDG 17" className="footer-img large-logo" />
                        </div>
                    </Col>

                    <Col xs={12} md={4} className="d-flex flex-column align-items-center">
                        <div className="contact-info">
                            <h4>Contact Info</h4>
                            <div className='stuff'>
                                <p>
                                    <a href="tel:+17187505676" style={{ textDecoration: "none", color: "inherit" }}>
                                        <FaPhone /> +1 718 750 5676
                                    </a>
                                </p>
                                <p>
                                    <a href="mailto:hello@bunchful.com" style={{ textDecoration: "none", color: "inherit" }}>
                                        <FaEnvelope /> hello@bunchful.com
                                    </a>
                                </p>
                                <p>
                                    <a href="https://www.google.com/maps/search/?api=1&query=New+York,+NY" target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none", color: "inherit" }}>
                                        <FaMapMarkerAlt /> New York, NY
                                    </a>
                                </p>
                            </div>
                        </div>
                    </Col>

                    {/* Social Media Section */}
                    <Col xs={12} md={4} className="d-flex flex-column align-items-center">
                        <h3 className='follow-us'>Follow us on</h3>
                        <div className="social-icons mb-4">
                            <a href="https://www.facebook.com/bebunchful" target="_blank" rel="noopener noreferrer" aria-label="Follow us on Facebook">
                                <FaFacebook />
                            </a>
                            <a href="https://www.youtube.com/@bebunchful" target="_blank" rel="noopener noreferrer" aria-label="Follow us on YouTube">
                                <FaYoutube />
                            </a>
                            <a href="https://www.instagram.com/bebunchful/" target="_blank" rel="noopener noreferrer" aria-label="Follow us on Instagram">
                                <FaInstagram />
                            </a>
                            <a href="https://x.com/bebunchful" target="_blank" rel="noopener noreferrer" aria-label="Follow us on X">
                                <FaTwitter />
                            </a>
                            <a href="https://www.linkedin.com/company/bunchful/" target="_blank" rel="noopener noreferrer" aria-label="Follow us on LinkedIn">
                                <FaLinkedin />
                            </a>
                        </div>
                    </Col>
                </Row>

                {/* Footer Copyright and Message */}
                <p style={{ fontSize: "1rem", textAlign: "center" }}>
                    Do you know what it means to <em>'be bunchful'</em>? <br />
                    To <em>'be bunchful'</em> is to share your gifts and abundance with others.
                </p>

                <div className="separator" style={{ height: "1px", backgroundColor: "white", margin: "20px 0px", width: "100%" }}></div>

                <p style={{ textAlign: "center" }}>
                    Copyright © 2024 Bunchful Inc. All Rights Reserved.
                    <Link to="/privacy-policy" style={{ marginLeft: "5px", textDecoration: "none", color: "inherit" }}>
                        Terms, Conditions and Privacy Policy
                    </Link>
                </p>
            </Container>
        </footer>

    );
};

export default Footer;